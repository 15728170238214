import { useEffect, useRef, useState } from 'react';

export const useAsync = <P>(fn: () => MaybePromise<P>, deps: any[]) => {
  const [state, setState] = useState<{
    loading: boolean;
    error: Error | undefined;
    value: P | undefined;
  }>({
    error: undefined,
    value: undefined,
    loading: true,
  });

  const requestCounter = useRef(0);

  const latestFn = useRef(fn);
  latestFn.current = fn;

  useEffect(() => {
    const requestIndex = ++requestCounter.current;
    if (requestIndex > 1) {
      setState({ value: undefined, error: undefined, loading: true });
    }

    void (async () => {
      try {
        const value = await latestFn.current();
        if (requestIndex === requestCounter.current) {
          setState({ value, error: undefined, loading: false });
        }
      } catch (err) {
        if (requestIndex === requestCounter.current) {
          setState({ value: undefined, error: err as Error, loading: false });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return state;
};
