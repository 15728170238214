import { Progress, SystemStyleObject } from '@chakra-ui/react';

const COLOR_SCHEMES = {
  gray: 'var(--chakra-colors-gray-400)',
  green: 'var(--chakra-colors-green-400)',
  brand: 'var(--chakra-colors-brand-500)',
  brand_gradient: [
    ['var(--chakra-colors-brand-500)', 23],
    ['var(--chakra-colors-orange-400)', 100],
  ],
  rainbow: [
    ['var(--chakra-colors-brand-500)', 16],
    ['var(--chakra-colors-orange-400)', 33],
    ['var(--chakra-colors-yellow-400)', 55],
    ['var(--chakra-colors-green-400)', 80],
    ['var(--chakra-colors-teal-400)', 100],
  ],
} as const;

type ProgressBarProps = {
  progressRatio: number | null;
  colorScheme?: keyof typeof COLOR_SCHEMES;
  variant?: 'border' | 'solid';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  sx?: SystemStyleObject;
  progressStyles?: string;
};
export const ProgressBar: React.FC<ProgressBarProps> = ({
  progressRatio,
  variant = 'solid',
  colorScheme = 'brand_gradient',
  size = 'sm',
  sx,
  progressStyles,
}) => {
  const colors = COLOR_SCHEMES[colorScheme];

  const css =
    progressStyles ||
    `& > div {
    transition-property: width;
    background: ${
      typeof colors === 'string'
        ? colors
        : `linear-gradient(90deg, ${colors
            .map((c) => `${c[0]} ${c[1]}vw`)
            .join(', ')})`
    }`;

  return (
    <Progress
      border={variant === 'border' ? '1px solid' : 'none'}
      borderColor={typeof colors === 'string' ? colors : colors[0][0]}
      // isIndeterminate={(progressRatio ?? 0) >= 1.0} // a fun animation effect once they reach 100%
      value={(progressRatio ?? 0) * 100.0}
      // `null` value will hide the progress bar
      bg={progressRatio == null ? 'transparent' : undefined}
      size={size}
      {...(size === 'xl' && { height: '32px !important' })}
      // TODO: fixes the progress-bar not animating. it's probably fixed when upgrading to @chakra-ui/react@2.8
      css={css}
      sx={sx}
    />
  );
};
